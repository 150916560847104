import "./VesselOperations.css";
import "./MarineTerminalCalendarView.css";
import DataNotFound from "../DataNotFound/DataNotFound";
 import IconComponent from "./IconComponent";
function MarineTerminalCalendar(props) {
  function getFullMonthName(monthNumber) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return monthNames[monthNumber - 1];
  }

  function getDayOfWeek(dateString) {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const date = new Date(dateString);
    const dayOfWeek = daysOfWeek[date.getDay()];

    return dayOfWeek;
  }
  return (
    <div className="">
   

<div>
  <IconComponent/>
</div>
  {/* <div>
     <a href = {Port_Holiday}
      Download = "2025 Port Holiday">
     <button 
                  className="downloadBtn"
                  // onClick={() => }
                >
                  {"2025 Port Holiday"}
                </button>
                </a>
                </div> */}
             
      <section className="section_view">

       
     
        { props.data && props.data.length > 0 ?  <div class="events">
          <ul>
            {props.data.map((holidayData, index) => {
              const dateStringParts = holidayData.holiday_date.split("-");
              const day = dateStringParts[2];
              const month = getFullMonthName(parseInt(dateStringParts[1], 10)); // Convert month number to full month name
              const year = dateStringParts[0];
              const dayOfWeek = getDayOfWeek(`${month} ${day}, ${year}`);
              return (
                <li
                  key={index}
                 
                  style={{ display: "block" }}
                >
                  <div className="time row">
                    <div
                      style={{
                        display: "flex",
                        // marginRight: "5px",
                        flexWrap: "nowrap",
                        alignItems: "center",
                      }}
                      className="col-sm-6"
                    >
                      <div className="date">
                        <div className="month">
                          {month} {year}
                        </div>
                        <div className="day">
                          <h1>{day}</h1>
                        </div>
                      </div>
                      <div className="day_value">{dayOfWeek}</div>
                    </div>
                    <div className="col-sm-6 day_value" style={{
    fontFamily: "Open Sans",
    fontSize: "30px"
                    }}>
                     {holidayData.data[0].holiday_event}
                    </div>
                  </div>

                  {holidayData.data.map((holiday, idx) => {
                    return (
                      <div className="row">
                          <div class="container-base">
                      <div class="container-circular-image">
        <div class="circular-image">
            <img 
             src={
                              holiday.terminal_logo !== null &&
                              holiday.terminal_logo !== ""
                                ? holiday.terminal_logo
                                : ""
                            }
                            onError={(e) => {
                              e.currentTarget.src = "";
                            }}
                        alt={holiday.marine_name}/>
                     </div>
                   </div>
  <p><h5>{holiday.holiday_type}</h5></p>
  <p>
<div
                              className={
                                holiday["first_shift"] == "2"
                                  ? "red_value"
                                  : holiday["first_shift"] == "0"
                                  ? "tbdvalue"
                                  : "green_value"
                              }
                            >
                              1<sup>st</sup> shift -{" "}
                              {holiday["first_shift"] == "2"
                                ? "Closed"
                                : holiday["first_shift"] == "0"
                                ? "TBD"
                                : "Open"}
                            </div>
 </p>
  <p>
 <div
                              className={
                                holiday["second_shift"] == "2"
                                  ? "red_value"
                                  : holiday["second_shift"] == "0"
                                  ? "tbdvalue"
                                  : "green_value"
                              }
                            >
                              2<sup>nd</sup> shift -{" "}
                              {holiday["second_shift"] == "2"
                                ? "Closed"
                                : holiday["second_shift"] == "0"
                                ? "TBD"
                                : "Open"}
                            </div>
</p>
</div>

                   
                       {idx == holidayData.data.length-1 ?  " " : <hr/>}
                      </div>
                    );
                  })}

                  <div style={{ clear: "both" }}></div>
                </li>
              );
            })}
          </ul>
          <div className="mt-4">
        <h6>
          <p>Schedules and SSCO participation subject to change.</p>
          <p>
            Visit terminal operator websites or contact terminal customer
            service for updated information.
          </p>
          <p>
            Note: Anticipate higher than normal gate volume preceding and
            following holidays.
          </p>
        </h6>
        </div>
        </div>
        : <div class="events"><DataNotFound/></div>
   }
      </section>
     
    </div>
  );
}

export default MarineTerminalCalendar;
