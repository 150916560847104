import React from 'react';
import './ButtonComponent.css'; // Assuming you'll put your CSS here
import Port_Holiday from "../../assets/Terminal_Gate_Hours/2025_Port_Holidays.pdf"
import { languageJson } from "../../StringVariable";
const IconComponent = () => {
    return (
      <>
  
        <div className="holiday-button-container">
        <a href = {Port_Holiday}
            Download = "2025 Port Holiday">
            <button className='holiday-button'>
                <span className="text">{languageJson.port_holiday_pdf_title}</span>
                <div className="icon-container">
                <div className="icon icon--left">
                    <svg>
                    <use xlinkHref="#arrow-right"></use>
                    </svg>
                </div>
                <div className="icon icon--right">
                    <svg>
                    <use xlinkHref="#arrow-right"></use>
                    </svg>
                </div>
                </div>
            </button>
          </a>
        </div>
  
        <svg style={{ display: 'none' }}>
          <symbol id="arrow-right" viewBox="0 0 20 10">
            <path d="M14.84 0l-1.08 1.06 3.3 3.2H0v1.49h17.05l-3.3 3.2L14.84 10 20 5l-5.16-5z"></path>
          </symbol>
        </svg>
      </>
    );
  };

export default IconComponent;
